import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const LocationPage = () => (
	<Layout
		meta={{
			title: "Online? Remote? Digital!",
			description: "Accento Digital happens online, but captures the rich atmosphere of offline events",
			keywords: "digital",
			path: "/digital",
		}}
		wide
	>
		<Snippet id="digital" />
		<Snippet id="digital-climate" />
		<Snippet id="digital-conference" />
		<Snippet id="digital-tickets" />
	</Layout>
)

export default LocationPage
